import { store as sparkyStore } from 'sparky-framework';
import { MSP_ENABLED } from 'src/version';
import { getStoreState } from 'src/ui-lib/core/utils/storeRegistry';
import _ from 'lodash';
import { checkIsFeatureEnabled } from 'src/utils/featureFlagUtils';
import { ALL, DEFAULT_SNIPPET, GPCS_CONTAINERS, PRISMA_ACCESS_KEY, SNIPPET } from 'src/service-lib/services/constants';
import Pan from 'src/ui-lib/core/autorender/schema/Pan';
import { getAuthState } from '@sparky/framework';
import { APP_BASE_URL_PREFIX } from 'src/AppConfig';
import { isManageEnabled, isNotificationCallComplete } from 'src/loadUtils';
import { isAppOnMaintenance } from 'src/containers/main/notifications/utils';
import StartupManager from 'src/containers/main/StartupManager';
import { CONTAINER_PARAM, CONTAINER_TYPE_PARAM, PANORAMA_CONFIG } from 'src/constants';

export const getFawkesMainFromSparkyStore = () => {
	return sparkyStore?.getState()?.fawkesMain || sparkyStore?.getState()?.main || {};
};

export const updateFawkesMainInSparkyStore = (fawkesMain) => {
	sparkyStore.dispatch({
		type: 'set-values',
		values: {
			fawkesMain: fawkesMain,
		},
	});
};
export const getConfigLocationFromURL = () => {
	const url = new URL(document.URL);
	const search = url.searchParams;
	const path = window.location.pathname;
	const [, , containerKey] = path.split('/');
	const containerKeyFromParam = search.get(CONTAINER_PARAM);
	const containerTypeFromParam = search.get(CONTAINER_TYPE_PARAM);
	return getConfigLocation(containerKeyFromParam, containerTypeFromParam, containerKey);
};

export const getConfigLocation = (containerKeyFromParam, containerTypeFromParam, containerKey) => {
	const paDisabled = isPADisabled();
	const isMSP = isBulkConfigEnabled();
	const defaultContainer = isMSP
		? {
				name: DEFAULT_SNIPPET,
				type: SNIPPET,
				locationPath: DEFAULT_SNIPPET,
		  }
		: !paDisabled && isNGFWDisabled()
		? GPCS_CONTAINERS[PRISMA_ACCESS_KEY]
		: {
				name: ALL,
				type: 'container',
				locationPath: ALL,
		  };

	const defaultContainerKey = isMSP ? DEFAULT_SNIPPET : ALL;
	const PA_CONATINER = paDisabled ? {} : GPCS_CONTAINERS;
	if (_.isEmpty(containerKeyFromParam)) {
		if (_.has(PA_CONATINER, containerKey)) {
			return {
				container: PA_CONATINER[containerKey],
			};
		}
		return {
			container: defaultContainer,
		};
	}
	const key = containerKeyFromParam || defaultContainerKey;

	if (PA_CONATINER[key]) {
		return {
			container: PA_CONATINER[key],
		};
	} else {
		const container = {
			name: containerKeyFromParam,
			type: containerTypeFromParam,
			locationPath: containerKeyFromParam,
		};
		return {
			container: container,
		};
	}
};

export const isPADisabled = () => {
	// const url = new URL(document.URL);
	// const search = url.searchParams;
	// return search.has(CONTAINER_TYPE_PARAM);

	// to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	const { pa_enabled: isPAEnabled = false } = tenantInfo;
	return !isPAEnabled;
};

export const isNGFWDisabled = () => {
	// to decouple
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	const featuresInfo =
		_.get(getStoreState(), 'main.featuresInfo', [])?.length > 0
			? _.get(getStoreState(), 'main.featuresInfo', [])
			: fawkesMain?.featuresInfo;
	const isNGFWDisabledByFeatureFlag = checkIsFeatureEnabled(featuresInfo, 'isNGFWDisabled');
	const { ngfw_enabled: isNGFWEnabled = false } = tenantInfo;
	return isNGFWDisabledByFeatureFlag || !isNGFWEnabled;
};

export const isBulkConfigEnabled = () => {
	if (MSP_ENABLED) {
		return sparkyStore.getState().msp?.activated_features?.includes('BULK_CONFIG');
	} else {
		return false;
	}
};

export const tenantInfo = () => {
	const fawkesMain = getFawkesMainFromSparkyStore();
	const tenantInfo = _.get(getStoreState(), 'main.tenant') || fawkesMain.tenant || {};
	return tenantInfo;
};

export const isScmPremiumTenant = () => {
	return _.get(tenantInfo(), 'scmFlags.is_scm_premium_tenant');
};

export const isScmBaseTenant = () => {
	return _.get(tenantInfo(), 'scmFlags.is_scm_base_tenant');
};

export const isScmBaseTenantWithCDL = () => {
	return _.get(tenantInfo(), 'scmFlags.is_tenant_with_cdl');
};

export const isBluebirdOnboardingEnabled = () => {
	const featuresInfo = sparkyStore.getState()?.fawkesMain?.featuresInfo ?? [];
	return checkIsFeatureEnabled(featuresInfo, 'bluebird_onboarding');
};

export const getFolderNameByUUID = (uuid, main) => {
	const folderUUIDNameMap =
		main?.scopeManage?.folderUUIDNameMap || _.get(getStoreState(), 'main.scopeManage.folderUUIDNameMap', {});
	return folderUUIDNameMap[uuid];
};

export const getFolderPermissionSetFromScopes = ({ name, type }, scopes) => {
	const result = new Set();
	const userScopeInfo = _.get(getStoreState(), 'main.userScopeInfo', {});
	if (type === SNIPPET) {
		const allSnippets = _.get(getStoreState(), 'main.snippetManage.allSnippets', {});
		const uuid = allSnippets?.[name]?.id;
		scopes.forEach((scope) => {
			const snippetSet = userScopeInfo[scope]?.snippetSet;
			if (snippetSet?.has(uuid)) {
				result.add(scope);
			}
		});
	} else {
		const folderScopeMap = _.get(getStoreState(), 'main.scopeManage.folderScopeMap', {});
		let uuid = folderScopeMap?.[name]?.id;
		while (uuid && result.size === 0) {
			scopes.forEach((scope) => {
				const pathSet = userScopeInfo[scope]?.folderSet;
				if (pathSet?.has(uuid)) {
					result.add(scope);
				}
			});
			const currentFolderName = getFolderNameByUUID(uuid);
			const currentFolder = folderScopeMap?.[currentFolderName];
			uuid = currentFolder?.parent?.['id'];
		}
	}
	return Array.from(result);
};

export const getCurrentPredefinedAndCustomRoles = (hideScope) => {
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	let configLocation = _.get(getStoreState(), 'main.configLocation', {});
	if (_.isEmpty(configLocation)) {
		configLocation = getConfigLocationFromURL();
	}
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});

	const scopeList =
		getFolderPermissionSetFromScopes(configLocation.container || {}, Object.keys(scopeRolesMap)) || [];

	if (scopeList.length > 0 && !hideScope) {
		const roles = scopeList.reduce((acc, scope) => {
			const scopeRoles = scopeRolesMap?.[scope] || [];
			return [...acc, scopeRoles.map((item) => item?.name)];
		}, []);
		return roles;
	} else {
		const tsgId = _.get(auth, 'tsg_id', '');
		const currentRoles = auth?.access?.get(`prn:${tsgId}:prisma_access:::`)?.roleNames || [];
		const predefinedRoles = auth?.access?.get(`prn:${tsgId}::::`)?.roleNames || [];
		const roles = [...currentRoles, ...predefinedRoles];
		return roles;
	}
};

export const isUserRoleHasManagePermission = (navItem) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return (
		roles && Pan.isArray(roles) && roles.filter((r) => r !== 'auditor' && r !== 'adem_tier_1_support').length > 0
	);
};

export const isUserRoleAdemSupport = (navItem) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'adem_tier_1_support');
};

const isUserRoleDlpOrSaas = (navItem) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'datalosspreventionadmin' || r === 'saasadmin');
};
export const isAdminRole = (navItem) => {
	const roles = getCurrentPredefinedAndCustomRoles(navItem?.hideScope);
	return Array.isArray(roles) && roles.some((r) => r === 'superuser' || r === 'msp_superuser');
};

export const isUserRoleSWGAdmin = () => {
	const roles = getCurrentPredefinedAndCustomRoles();
	return roles && Pan.isArray(roles) && roles.filter((r) => r === 'web_security_admin').length > 0;
};

export const hasRoleExists = (roleName, includeScope = false) => {
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	const tsgId = _.get(auth, 'tsg_id', '');
	const defaultRole = auth?.access?.get(`prn:${tsgId}:prisma_access:::`);
	const hasInDefaultRole = defaultRole?.roleNames?.indexOf('roleName') >= 0;
	if (hasInDefaultRole) return true;
	if (!includeScope) return false;
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});
	const scopeNames = Object.keys(scopeRolesMap);
	for (let i = 0; i < scopeNames.length; i++) {
		for (let j = 0; j < scopeRolesMap[scopeNames[i]].length; j++) {
			if (scopeRolesMap[scopeNames[i]][j]?.name === roleName) {
				return true;
			}
		}
	}
};

export function isViewPermissionEnabled(permission) {
	return permission === 'enable' || permission === 'read-only';
}

export const getNavItemByRBAPath = (rbaPath) => {
	const flattenNavigationList = _.get(getStoreState(), 'main.flattenNavigationList', []);
	return flattenNavigationList.find((nav) => nav.rbaPath === rbaPath);
};

export const checkParentPermit = (permissionPath, ancetorPath, permissionSetMap) => {
	let currentPath = permissionPath;
	while (permissionSetMap?.get(currentPath)) {
		const cur = permissionSetMap?.get(currentPath);
		if (cur?.name === ancetorPath) return true;
		currentPath = cur?.parent;
		if (!currentPath) {
			break;
		}
	}
	return false;
};

export function getRBAPermissionByRBAC(rbaPath, config = null) {
	const navItem = getNavItemByRBAPath(rbaPath);
	const scopeRelated = !navItem?.hideScope;
	const auth = _.get(sparkyStore.getState(), 'auth', {});
	let configLocation = {};
	if (config) {
		configLocation = config;
	} else {
		configLocation = _.get(getStoreState(), 'main.configLocation', {});
	}
	if (_.isEmpty(configLocation)) {
		configLocation = getConfigLocationFromURL();
	}

	const PRISMA_ACCESS_PREFIX = 'prisma_access.';
	const routeToTest = PRISMA_ACCESS_PREFIX + rbaPath;
	const scopeRolesMap = _.get(getStoreState(), 'main.scopeRolesMap', {});

	let prismaHasAccessForParticularRoute = 'disable';
	if (rbaPath) {
		// ********* ALL APPS SERVICES & PRISMA ACCESS APP CHECK BEGIN *********
		// { scopes: { app_id:'prisma_access' }} means include all apps and services & prisma_access app
		if (auth?.access?.checkPermissionSet(routeToTest, { scopes: { app_id: 'prisma_access' } }) === 'write') {
			prismaHasAccessForParticularRoute = 'write';
		}
		if (auth?.access?.checkPermissionSet(routeToTest, { scopes: { app_id: 'prisma_access' } }) === 'read') {
			prismaHasAccessForParticularRoute = 'read';
		}
		// ********* ALL APPS SERVICES & PRISMA ACCESS APP CHECK END *********
	} else {
		for (let i = 0; i < auth?.access?.length; i++) {
			if (auth?.access[i]?.app_id === 'prisma_access' || auth?.access[i]?.app_id === '') {
				//empty means all apps&service
				if (auth?.access[i]?.aggPermissionSets?.has('prisma_access.all')) {
					if (auth?.access[i]?.aggPermissionSets?.get('prisma_access.all')?.access_types?.includes('write')) {
						prismaHasAccessForParticularRoute = 'write';
					}
					if (auth?.access[i]?.aggPermissionSets?.get('prisma_access.all')?.access_types?.includes('read')) {
						prismaHasAccessForParticularRoute = 'read';
					}
				}
			}
		}
	}

	// ********* SCOPE CHECK BEGIN *********
	if (scopeRolesMap && Object.keys(scopeRolesMap).length !== 0) {
		// We have scopes for this user - so check
		const scopeList =
			getFolderPermissionSetFromScopes(configLocation.container || {}, Object.keys(scopeRolesMap)) || [];
		// console.info('log......', auth,scopeList,routeToTest, configLocation.container?.name, scopeRolesMap, scopeRelated)
		const permissionSetMap = new Map();
		auth?.rolePermissionDefinition?.permissionSets?.forEach((permit) => {
			permissionSetMap?.set(permit.name, permit);
		});
		if (scopeList.length > 0 && scopeRelated) {
			//union roles in scope
			let R = false,
				W = false;
			scopeList.forEach((scope) => {
				const rolesInScope = scopeRolesMap?.[scope] || [];
				rolesInScope.forEach((role) => {
					role.permission_sets?.forEach((permit) => {
						if (
							permit.id === routeToTest ||
							permit.id === 'prisma_access.all' ||
							checkParentPermit(routeToTest, permit.id, permissionSetMap)
						) {
							if (permit?.access_types?.includes('write')) {
								W = true;
							}
							if (permit?.access_types?.includes('read')) {
								R = true;
							}
						}
					});
				});
			});
			return W ? 'enable' : R ? 'read-only' : 'disable';
		}
	}
	// ********* SCOPE CHECK END *********

	// *********  FALLBACK - IF NO SCOPES PRESENT, USE ALL APPS SERVICES + PRISMA ACCESS APP CHECK TO RETURN BEGIN *********
	if (prismaHasAccessForParticularRoute === 'write') {
		return 'enable';
	} else if (prismaHasAccessForParticularRoute === 'read') {
		return 'read-only';
	} else {
		return 'disable';
	}
	// *********  FALLBACK - IF NO SCOPES PRESENT, USE ALL APPS SERVICES + PRISMA ACCESS APP CHECK TO RETURN END *********
}

export function getRBAPermission(rbaPath, config = null) {
	if (!_.isArray(rbaPath)) {
		return getRBAPermissionByRBAC(rbaPath, config);
	}
	let readOnly = false;
	//for multiple permission rba, we do union
	for (let i = 0; i < rbaPath.length; i++) {
		const currentRbaPath = rbaPath[i];
		const permission = getRBAPermissionByRBAC(currentRbaPath, config);
		if (permission === 'enable') {
			return 'enable';
		} else if (permission === 'read-only') {
			readOnly = true;
		}
	}
	return readOnly ? 'read-only' : 'disable';
}

function buildIsAvailArgs() {
	const { fawkesMain = {} } = sparkyStore.getState();
	const { configLocation, instances, licenseInfo, featuresInfo } = fawkesMain;
	const container = configLocation?.container;

	// Below are args are passed from Sparky for isAvail functions for routes
	// These same args are constructed here for the sake of consistency for
	// runtime co-pilot navigation isAllowed function
	return [
		{
			auth: getAuthState(),
			configLocation,
			container,
			instances,
			licenseInfo,
			featuresInfo,
		},
		licenseInfo,
		featuresInfo,
	];
}

function configAvail(...args) {
	return (
		!(
			StartupManager.getConfigManagedType() === PANORAMA_CONFIG &&
			!StartupManager.isMigrationStateEligibleForFawkesUI()
		) &&
		isManageEnabled(...args) &&
		isNotificationCallComplete() &&
		!isAppOnMaintenance()
	);
}

export function getSiteMap() {
	return [
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/security`,
			title: 'Security Policy',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = ['security_policy', 'web_security'];
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/anti-spyware`,
			title: 'Anti-Spyware',
			isAllowed: function () {
				const rbaPath = 'anti_spyware';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/vulnerability-protection`,
			title: 'Vulnerability Protection',
			isAllowed: function () {
				const rbaPath = 'vulnerability_protection';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/wildfire-and-antivirus`,
			title: 'WildFire and Antiivirus',
			isAllowed: function () {
				const rbaPath = 'wildfire_antivirus';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/dns-security`,
			title: 'DNS Security',
			isAllowed: function () {
				const rbaPath = 'dns_security';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/url-access-management`,
			title: 'URL Access Management',
			isAllowed: function () {
				const rbaPath = 'url_access_management';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/file-blocking`,
			title: 'File Blocking',
			isAllowed: function () {
				const rbaPath = 'file_blocking';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/http-header-insertion`,
			title: 'HTTP Header Insertion',
			isAllowed: function () {
				const rbaPath = 'http_header_insertion';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/security-profile-groups`,
			title: 'Profile Groups',
			isAllowed: function () {
				const rbaPath = 'profile_groups';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security`,
			title: 'Internet Security',
			isAllowed: function () {
				const rbaPath = 'web_security';
				const args = buildIsAvailArgs();
				return configAvail(...args) && isViewPermissionEnabled(getRBAPermission(rbaPath));
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption`,
			title: 'Decryption',
			isAllowed: function () {
				const rbaPath = 'decryption';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/dos-protection`,
			title: 'DoS Protection',
			isAllowed: function () {
				const rbaPath = 'dos_protection';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/ai-security`,
			title: 'AI Security',
			isAllowed: function () {
				const rbaPath = 'ai_security';
				const args = buildIsAvailArgs();
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/qos`,
			title: 'QoS',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'qos';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!MSP_ENABLED &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/application-override`,
			title: 'Application Override Policy',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'application_override';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/pbf`,
			title: 'Policy Based Forwarding',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'pbf';
				return (
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/nat`,
			title: 'NAT',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'nat';
				return (
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/security-services/sdwan`,
			title: 'SD-WAN Policy',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'sdwan_policy';
				const featuresInfo = sparkyStore.getState()?.fawkesMain?.featuresInfo;
				return (
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					checkIsFeatureEnabled(featuresInfo, 'sdwan') &&
					checkIsFeatureEnabled(featuresInfo, 'enable-frr-routing') &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication`,
			title: 'Authentication',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'authentication';

				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!MSP_ENABLED &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/directorySync`,
			title: 'Cloud Identity Engine',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'cloud_identity_engine';

				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!MSP_ENABLED &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/identityRedistribution`,
			title: 'Identity Redistribution',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'identity_redistribution';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!MSP_ENABLED &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/local-users-and-user-groups`,
			title: 'Local Users & Groups',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'local_users_groups';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!MSP_ENABLED &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/addresses`,
			title: 'Addresses',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'address';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/address-groups`,
			title: 'Address Groups',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'address_groups';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/regions`,
			title: 'Regions',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'regions';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/applications`,
			title: 'Applications',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'applications';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/application-groups`,
			title: 'Application Groups',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'application_groups';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/application-filters`,
			title: 'Application Filters',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'application_filters';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/traffic-objects`,
			title: 'Traffic Objects',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'traffic_object';
				return (
					configAvail(...args) && !isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath))
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/services`,
			title: 'Services',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'services';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/service-groups`,
			title: 'Service Groups',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'service_groups';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/saas-application-management`,
			title: 'SaaS Tenant Restrictions',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'saas_application_management';
				return (
					!isUserRoleDlpOrSaas() && isViewPermissionEnabled(getRBAPermission(rbaPath)) && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/hip-objects`,
			title: 'HIP Objects',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'global_hip_objects';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/hip-profiles`,
			title: 'HIP Profiles',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'global_hip_profiles';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-user-group`,
			title: 'Dynamic User Groups',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'dynamic_user_groups';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/tags`,
			title: 'Tags',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'tags';
				return (
					!MSP_ENABLED &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/auto-tag-actions`,
			title: 'Auto-Tag Actions',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'auto_tags';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding`,
			title: 'Log Forwarding Profile',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'log_forwarding';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/devices`,
			title: 'Devices',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'device_object';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-block-lists`,
			title: 'External Dynamic Lists',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'external_dynamic_lists';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) && !isUserRoleDlpOrSaas() && configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management`,
			title: 'Certificate Management',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'certificate_management';
				return (
					!MSP_ENABLED &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/objects/schedules`,
			title: 'Schedules',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'schedules';
				return (
					!MSP_ENABLED &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces`,
			title: 'Interfaces',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'interfaces';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/zones`,
			title: 'Zones',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'zones';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/virtual-wire`,
			title: 'Virtual Wire',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'virtual_wire';
				const featuresInfo = sparkyStore.getState()?.fawkesMain?.featuresInfo;
				return (
					checkIsFeatureEnabled(featuresInfo, 'vwire') &&
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/routing`,
			title: 'Routing',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'device_object';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/ipsec-tunnel`,
			title: 'IPSec',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'ipsec_tunnels';

				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/dhcp`,
			title: 'DHCP',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'dhcp';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/dns-proxy`,
			title: 'DNS Proxies',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'dns_proxy';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect`,
			title: 'GlobalProtect',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'globalprotect';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/device-setup`,
			title: 'Device Setup',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'device_setup';
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/proxy`,
			title: 'Proxy',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'hybrid_swg';
				const featuresInfo = sparkyStore.getState()?.fawkesMain?.featuresInfo;
				return (
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					!checkIsFeatureEnabled(featuresInfo, 'isWebSecurityProxyDisabled') &&
					!isUserRoleDlpOrSaas() &&
					!isNGFWDisabled() &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/device-settings/device-admin`,
			title: 'Administrators',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				// No RBA check
				return !isUserRoleDlpOrSaas() && !isNGFWDisabled() && configAvail(...args);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/workflow/auto-vpn`,
			title: 'Auto VPN',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'auto_vpn';
				const featuresInfoArray = sparkyStore.getState()?.fawkesMain?.featuresInfo;
				return (
					!isNGFWDisabled() &&
					isViewPermissionEnabled(getRBAPermission(rbaPath)) &&
					checkIsFeatureEnabled(featuresInfoArray, 'enable-frr-routing') &&
					configAvail(...args)
				);
			},
		},
		{
			path: `${APP_BASE_URL_PREFIX}/saas-application-endpoints`,
			title: 'SaaS Application Endpoints',
			isAllowed: function () {
				const args = buildIsAvailArgs();
				const rbaPath = 'saas_application_management';
				return configAvail(...args) && isViewPermissionEnabled(getRBAPermission(rbaPath));
			},
		},
	];
}
